import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';

import ContentTitle from '../components/ContentTitle';
import Layout from '../components/layout';
import CarCard from '../components/CarCard';
import { devices } from '../styles';
import bg from '../images/bg_light_logo.svg';

const CarList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 120px;

  @media ${devices.mobile} {
    display: block;
  }
`;

class IndexPage extends React.PureComponent {
  render() {
    const cars = get(this, 'props.data.cars.edges');
    const soldCars = get(this, 'props.data.soldCars.edges');

    return (
      <Layout isIndex>
        <CarList>
          {cars.map(({ node }) => (
            <Link to={`/inventory/${node.slug}`} key={node.slug}>
              <CarCard
                name={node.name}
                images={node.images}
                description={node.shortDescription}
                isSold={node.sold}
                price={node.cost}
                slug={node.slug}
              />
            </Link>
          ))}
          {soldCars.map(({ node }) => (
            <Link to={`/inventory/${node.slug}`} key={node.slug}>
              <CarCard
                name={node.name}
                images={node.images}
                description={node.shortDescription}
                isSold={node.sold}
                price={node.cost}
                slug={node.slug}
              />
            </Link>
          ))}
        </CarList>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query CarIndexQuery {
    cars: allContentfulCar(
      filter: { sold: { eq: false } }
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
      node {
          name
          slug
          shortDescription
          sold
          cost
          images {
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio:1.7777777777, placeholder: BLURRED)
          }
        }
      }
}
    soldCars: allContentfulCar(
      filter: { sold: { eq: true } }
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
      node {
          name
          slug
          shortDescription
          sold
          cost
          images {
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio:1.7777777777, placeholder: BLURRED)
          }
        }
      }
    }
}
`;

export default IndexPage;
