/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import Helmet from 'react-helmet';
import querystring from 'querystring-es3';
import Layout from '../components/layout';
import ContentTitle from '../components/ContentTitle';
import ContactPage from '../components/ContactPage';
import Form from '../components/Form';
import Icon from '../components/Icon';

const formTarget = '/.netlify/functions/mail?to=info@limerencemotors.com';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      isSending: false,
    };
  }

  handleInput = e => {
    const { formData } = this.state;

    formData[e.target.name] = e.target.value;

    return this.setState({ formData });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isSending: true });

    const body = querystring.stringify(this.state.formData);

    fetch(formTarget, {
      method: 'POST',
      body,
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Failed to send form.');
        }
        return response.json();
      })
      .then(json => {
        if (json.status !== 200) {
          throw new Error('Failed to send form.');
        }

        return this.setState({
          formSuccess: true,
          formError: null,
          isSending: false,
        });
      })
      .catch(err =>
        this.setState({ formError: err.message, isSending: false })
      );
  };

  render() {
    const { formError, formSuccess, isSending } = this.state;
    return (
      <Layout>
        <Helmet>
          <title>Contact Us</title>
        </Helmet>
        <ContactPage>
          <ContentTitle>Contact Us</ContentTitle>
          {formError && <div>Error: {formError}</div>}
          {formSuccess ? (
            <div>Thanks for reaching out! We will get back to you soon!</div>
          ) : (
            <Form onSubmit={this.handleSubmit}>
              <Form.Label htmlFor="name">Name</Form.Label>
              <Form.Input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                required
                value={this.state.formData.name}
                onChange={this.handleInput}
              />
              <Form.Label htmlFor="email">Email Address</Form.Label>
              <Form.Input
                type="email"
                id="email"
                name="email"
                placeholder="email@address.com"
                required
                value={this.state.formData.email}
                onChange={this.handleInput}
              />
              <Form.Label htmlFor="phone">Phone Number</Form.Label>
              <Form.Input
                type="tel"
                id="phone"
                name="phone"
                placeholder="012-345-6789"
                required
                value={this.state.formData.phone}
                onChange={this.handleInput}
              />
              <Form.Label htmlFor="message">Message</Form.Label>
              <Form.TextArea
                id="message"
                name="message"
                placeholder="Type Here"
                required
                value={this.state.formData.message}
                onChange={this.handleInput}
              />
              <Form.Button
                type="submit"
                style={{ float: 'right' }}
                disabled={isSending}
              >
                <Icon name="envelope" /> {isSending ? 'Sending...' : 'Send'}
              </Form.Button>
            </Form>
          )}
        </ContactPage>
      </Layout>
    );
  }
}

export default Contact;
